import styled from 'styled-components';
import { Header } from './components/Header';
import { useGetListerRequestsByDayQuery } from '../../../services/api/list-vehicles';
import { ListerDayBlock } from './components/DayBlock';
import { getDayNumberFromDate } from './utils/formatDate';
import moment from 'moment';
import { DndContext, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { useHandleDragStart } from './hooks/useHandleDragStart';
import { DragItem } from './components/DragItem';
import { useGetListerDraggableItem } from '../../../services/listerDashboard/listerDashboardSlice';
import { useHandleDragEnd } from './hooks/useHandleDragEnd';
import { useHandleDragOver } from './hooks/useHandleDragOver';

const Wrapper = styled.div`
  background: #fff;
  position: relative;
  width: 100%;
`;

const MainContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 24px;
  margin-top: 24px;
  width: 100%;
  gap: 16px;
`;

const ListerDashboard = () => {
  const { data } = useGetListerRequestsByDayQuery();

  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 300,
      tolerance: 8
    }
  });
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 8
    }
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragStart = useHandleDragStart();
  const handleDragEnd = useHandleDragEnd();
  const handleDragOver = useHandleDragOver();

  const activeItem = useGetListerDraggableItem();

  return (
    <DndContext sensors={sensors} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver}>
      <Wrapper>
        <Header activeDayNumber={getDayNumberFromDate(moment())} />
        <MainContentWrapper>
          {data?.map((el, i) => (
            <ListerDayBlock key={el[0]?.id || i} dayNumber={i} data={el} />
          ))}
        </MainContentWrapper>
      </Wrapper>
      <DragOverlay>{activeItem && <DragItem item={activeItem} />}</DragOverlay>
    </DndContext>
  );
};

export default ListerDashboard;
