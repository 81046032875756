/* eslint-disable no-else-return */
import styled from 'styled-components';
import Tooltip from '../../../../components/Tooltip';
import { useDispatch } from 'react-redux';
import { loginAsUser } from '../../../../actions';
import { HoverInfoTooltip } from './HoverInfo';
import { useCustomerHoverStateHandlers } from '../hooks';
import { useRef } from 'react';

const Wrapper = styled.div`
  max-width: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
`;

const StyledStatus = styled.span`
  color: ${props => props.statusColor || '#000'};
  cursor: pointer;
`;

const Role = ({ name, seller, userId = null, loginOnClick = false, adminNavPage = '', showHoverInfo = false }) => {
  const onlyAchFlooring =
    seller &&
    seller.purchase_options &&
    seller.purchase_options.find(({ option_name }) => option_name === 'ach_flooring') &&
    seller.purchase_options.length === 1;

  const dispatch = useDispatch();

  const handleClick = () => {
    if (!loginOnClick) return;
    dispatch(loginAsUser({ id: seller.user_id, initialPage: adminNavPage }).request);
  };

  const { isOpen, handleClose, handleOpen } = useCustomerHoverStateHandlers();

  const ref = useRef(null);

  if (showHoverInfo && userId) {
    return (
      <HoverInfoTooltip
        anchorEl={ref.current}
        initialPage={adminNavPage}
        userId={userId}
        isPopoverOpened={isOpen}
        closeTooltip={handleClose}
        tooltipName={name}
        maxWidth={65}
      >
        <Wrapper onClick={handleOpen}>
          <StyledStatus ref={ref} statusColor={onlyAchFlooring ? '#009F0F' : 'black'}>
            {name}
          </StyledStatus>
        </Wrapper>
      </HoverInfoTooltip>
    );
  }

  return (
    <Wrapper>
      <Tooltip title={name} arrow light maxWidth={75}>
        <StyledStatus onClick={handleClick} statusColor={onlyAchFlooring ? '#009F0F' : 'black'}>
          {name}
        </StyledStatus>
      </Tooltip>
    </Wrapper>
  );
};

export default Role;
