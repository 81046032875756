import DatePicker from './CheckboxDatePicker';
import { NotesComponent } from './NotesComponent';
import { StatusComponent } from './StatusComponent';
import { Actions } from './Actions';
import { getTableRow } from './styles';
import { ListVehiclePoolRowFromServer, ListVehiclePoolStatus } from './types';
import {
  useDeletePoolMutation,
  useDuplicatePoolMutation,
  useSubmitPoolMutation
} from '../../../../services/api/list-vehicles';
import { SellerRequests } from './SellerRequests';
import { useDispatch } from 'react-redux';
import { SchedulePool } from './SchedulePool';
import { NumberOfVehicles } from './NumberOfVehicles';
import { ConfirmDeleteStoreModal } from '../ConfirmDeleteModal';
import { loginAsUser, modalsToggle } from '../../../../actions';
import { useUpdatePoolQueryData } from '../../hooks/useUpdatePoolsQuery';
import styled from 'styled-components';

const TableRow = getTableRow('div');

type Action = 'duplicateAction' | 'editAction' | 'submitAction' | 'deleteAction';

const getActionsByStatus = (status: string): Action[] => {
  if (status === ListVehiclePoolStatus.Submitted) {
    return ['duplicateAction', 'editAction', 'deleteAction'];
  }

  if (status === ListVehiclePoolStatus.NewListing) {
    return ['duplicateAction', 'editAction', 'deleteAction', 'submitAction'];
  }

  if (status === ListVehiclePoolStatus.Done) {
    return [];
  }

  return [];
};

const StyledListerName = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const ListVehiclesRow = ({ item }: { item: ListVehiclePoolRowFromServer }) => {
  const [deletePool] = useDeletePoolMutation();
  const [submitPool] = useSubmitPoolMutation();
  const [duplicatePool] = useDuplicatePoolMutation();
  const dispatch = useDispatch<any>();

  const handleDelete = () => {
    deletePool(item.id);
    modalsToggle(`delete-pool-${item.id}`);
  };

  const updatePoolQuery = useUpdatePoolQueryData();

  const actionsMap: Record<Action, () => any> = {
    duplicateAction: () => duplicatePool(item.id),
    deleteAction: () => dispatch(modalsToggle(`delete-pool-${item.id}`)),
    submitAction: () => submitPool(item.id),
    editAction: () => {
      updatePoolQuery(draft => {
        const curr = draft.find(e => e.id === item.id);
        if (!curr) {
          return;
        }

        curr.actionStatus = 'editing';
      });
    }
  };

  const actionProps = getActionsByStatus(item.status).reduce((acc, curr) => {
    return {
      ...acc,
      [curr]: actionsMap[curr]
    };
  }, {} as Record<Action, () => any>);

  const handleListerClick = () =>
    dispatch(loginAsUser({ id: item.lister_id, initialPage: '/admin/vehicles?pool_opened=true' }).request);

  return (
    <TableRow>
      <SchedulePool item={item} />
      <StyledListerName onClick={handleListerClick}>{item.lister?.first_name}</StyledListerName>
      <SellerRequests item={item} />
      <NumberOfVehicles listRequests={item.listRequests} />
      <DatePicker value={item.seller_preferable_date} />
      <NotesComponent poolId={item.id} />
      <DatePicker value={item.preferable_date} />
      <StatusComponent status={item.status} />
      <Actions {...actionProps} />
      <ConfirmDeleteStoreModal id={item.id} handleDelete={handleDelete} />
    </TableRow>
  );
};
