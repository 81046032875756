import { Dispatch } from 'react';
import apiSlice from '..';
import { snackShow } from '../../../actions';
import { ListVehiclePoolRowFromServer } from '../../../components/ListVehicles/components/ListVehiclesRow/types';
import { WS_LISTER_LOCATION_UPDATE, WS_LR_CHAT_MESSAGE, WS_VEHICLE_NOTES_UPDATE } from '../../../constants/actionTypes';
import getSocket from '../../socket';
import { SocketMessage } from '../types';
import { useSelector } from 'react-redux';
import { ListVehiclesState } from '../../listVehiclesSlice/listVehiclesSlice';

type RequestListVehiclesPayload = {
  number_of_vehicles?: string;
  preferable_date?: string;
  notes?: string;
};

export type CreateVehiclePoolRowPayload = {
  lister_id: number;
  seller_ids: number[];
  preferable_date: string | null;
  seller_preferable_date: string | null;
  notes?: string;
};

export type UpdateVehiclePoolRowPayload = {
  id: number;
  lister_id: number;
  list_requests: any[];
  preferable_date: string | null;
  seller_preferable_date: string | null;
  notes?: string;
};

export type RequestsByDay = RequestListVehiclesPayload & {
  user: {
    id: number;
    dealership_name: string;
    city: string;
    street: string;
    zip: string;
    state: string;
  };
  status: string;
  id: number;
};

export type ListerPool = {
  id: number;
  lister_id: number;
  listRequests: RequestsByDay[];
};

export type RouteLeg = {
  durationInSeconds: string | null;
  localizedDuration: string | null;
  distanceInMeters: number | null;
};

export type RouteResponse = {
  durationInSeconds: string | null;
  localizedDuration: string | null;
  encodedPolyline: string | null;
  preferable_date: string | null;
  legs: Array<RouteLeg>;
  points: Array<{ latitude: number; longitude: number }>;
  geocodingResults: {
    origin: { placeId: string };
    intermediates: Array<{ placeId: string }>;
    destination: { placeId: string };
  };
};

export type ChatMessage = {
  id: number;
  message: string;
  created_at: string;
  user: {
    id: number;
    dealership_name: string;
  };
};

type UserGeolocation = {
  user_id: number;
  geolocation: {
    lat: number;
    long: number;
    timestamp: number;
    id: number;
  };
  routes: RouteResponse[];
  color?: string;
};

export const listVehiclesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    requestListVehicles: builder.mutation<void, RequestListVehiclesPayload>({
      query: body => ({
        url: `/pools/request`,
        body,
        method: 'POST'
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ message: 'Successfully submitted' }));
        } catch (e) {
          dispatch(snackShow({ message: 'Error while submitting submitting vehicle' }));
        }
      }
    }),
    getVehiclesPools: builder.query<ListVehiclePoolRowFromServer[], Record<string, string> | void>({
      query: params => ({
        url: `/pools`,
        params: params || undefined,
        method: 'GET'
      }),
      providesTags: ['VehiclesPool'],
      transformResponse: (r: any) => r.data
    }),
    createVehiclesPoolRow: builder.mutation<ListVehiclePoolRowFromServer, void>({
      query: body => ({
        url: `/pools`,
        body,
        method: 'POST'
      }),
      transformResponse: (r: any) => r.data
    }),
    updateVehiclesPoolRow: builder.mutation<void, UpdateVehiclePoolRowPayload>({
      query: ({ id, ...body }) => ({
        url: `/pools/${id}`,
        body,
        method: 'PUT'
      }),
      invalidatesTags: ['VehiclesPool'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ message: 'Row uploaded' }));
        } catch (e) {
          dispatch(
            snackShow({
              message: 'Error while uploading record',
              type: 'error'
            })
          );
        }
      }
    }),
    deleteDraftVehiclePoolRows: builder.mutation<void, void>({
      query: () => ({
        url: `/pools/draft`,
        method: 'DELETE'
      }),
      invalidatesTags: ['VehiclesPool']
    }),
    getListers: builder.query<Array<{ id: number; first_name: string }>, void>({
      query: () => ({
        url: `/pools/listers`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
    getSellers: builder.query<Array<{ id: number; dealership_name: string }>, void>({
      query: () => ({
        url: `/pools/sellers`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data
    }),
    getRequestsByDay: builder.query<RequestsByDay[][], string>({
      query: weekType => ({
        url: `pools/requests/by-day`,
        params: { weekType },
        method: 'GET'
      }),
      providesTags: ['VehiclesPool', 'PoolRequestsByDay'],
      transformResponse: (r: any) => r.data
    }),
    updateRequestPreferableDate: builder.mutation<void, { requestId: number; dayNumber: number }>({
      query: ({ requestId, dayNumber }) => ({
        url: `/pools/requests/by-day/${requestId}`,
        body: { dayNumber },
        method: 'PATCH'
      })
    }),
    addRequestToPool: builder.mutation<void, { poolId: number; requestId: number }>({
      query: ({ poolId, requestId }) => ({
        url: `/pools/${poolId}/add-request`,
        body: { requestId },
        method: 'POST'
      })
    }),
    deletePool: builder.mutation<void, number>({
      query: poolId => ({
        url: `/pools/${poolId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['VehiclesPool', 'PoolRequestsByDay']
    }),
    toggleSchedulePool: builder.mutation<void, number>({
      query: poolId => ({
        url: `pools/${poolId}/schedule`,
        method: 'POST'
      }),
      invalidatesTags: ['VehiclesPool']
    }),
    submitPool: builder.mutation<void, number>({
      query: poolId => ({
        url: `/pools/${poolId}/submit`,
        method: 'PATCH'
      }),
      invalidatesTags: ['VehiclesPool'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ message: 'Stores were successfully submitted to the Lister' }));
        } catch (e) {
          dispatch(snackShow({ message: 'Error while submitting stores to the Lister' }));
        }
      }
    }),
    duplicatePool: builder.mutation<void, number>({
      query: poolId => ({
        url: `pools/${poolId}/duplicate`,
        method: 'POST'
      }),
      invalidatesTags: ['VehiclesPool']
    }),
    deleteVehicleRequest: builder.mutation<void, number>({
      query: requestId => ({
        url: `/pools/requests/${requestId}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['PoolRequestsByDay']
    }),
    removeVehicleRequestPoolId: builder.mutation<void, number>({
      query: requestId => ({
        url: `pools/request/${requestId}`,
        method: 'PATCH'
      }),
      invalidatesTags: ['PoolRequestsByDay']
    }),
    getListerRequestsByDay: builder.query<ListerPool[][], void>({
      query: () => ({
        url: 'lister/pools/by-day'
      }),
      providesTags: ['ListerListRequests'],
      transformResponse: (r: any) => r.data
    }),
    listerMarkAsDone: builder.mutation<void, number>({
      query: requestId => ({
        url: `lister/pools/requests/${requestId}/mark-done`,
        method: 'POST'
      }),
      invalidatesTags: ['ListerListRequests']
    }),
    getListerRouteDistances: builder.query<RouteResponse, { origin: string; destinations: string[]; poolId: number }>({
      query: ({ origin, destinations, poolId }) => ({
        url: `lister/get-route`,
        params: { origin, destinations: destinations.join(';'), poolId }
      }),
      transformResponse: (r: any) => r.data
    }),
    updateNumberOfVehicles: builder.mutation<void, { numberOfVehicles?: string; requestId: number }>({
      query: ({ numberOfVehicles, requestId }) => ({
        url: `pools/request/${requestId}/update`,
        method: 'PATCH',
        body: { numberOfVehicles }
      })
    }),
    optimizeRoad: builder.mutation<void, { listRequestIds: number[]; farthestFirst?: boolean }>({
      query: body => ({
        url: `lister/optimize-route`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['ListerListRequests'],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(snackShow({ message: 'Successfully optimized road' }));
        } catch (e) {
          dispatch(
            snackShow({
              message: 'Failed to optimize road. Make sure the road is valid and try again',
              type: 'error'
            })
          );
        }
      }
    }),
    getChatMessages: builder.query<ChatMessage[], number>({
      query: listRequestId => ({
        url: `pools/requests/${listRequestId}/chat`,
        method: 'GET'
      }),
      transformResponse: (r: any) => r.data,
      async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved, cacheDataLoaded, getState, dispatch }) {
        const socket = getSocket();

        const listener = ({ payload, type }: SocketMessage) => {
          if (type !== WS_LR_CHAT_MESSAGE) return;
          if (arg !== payload?.list_vehicle_request_id) return;

          updateCachedData(draft => {
            draft.push(payload);
          });
        };

        try {
          const { data } = await cacheDataLoaded;
          socket.emit('subscribe', `list-request-chat:${arg}`);
          socket.on('message', listener);
        } catch (error) {}

        await cacheEntryRemoved;
        socket.off('message', listener);
      }
    }),
    sendChatMessage: builder.mutation<void, { listRequestId: number; message: string }>({
      query: ({ listRequestId, message }) => ({
        url: `pools/requests/${listRequestId}/chat`,
        method: 'POST',
        body: { message }
      })
    }),
    setListRequestOrder: builder.mutation<void, Record<number, number>>({
      query: body => ({
        url: `pools/requests/set-order`,
        method: 'POST',
        body: { orderMap: body }
      })
    }),
    listerCreatePool: builder.mutation<void, any>({
      query: body => ({
        url: `lister/pools`,
        method: 'POST',
        body
      }),
      invalidatesTags: ['ListerListRequests']
    }),
    saveGeo: builder.mutation<void, { lat: number; long: number; timestamp: number }>({
      query: body => ({
        url: `users/save-geo`,
        method: 'POST',
        body
      })
    }),
    getGeo: builder.query<UserGeolocation[], { date?: string; listerId?: number; currentWeekOnly?: boolean }>({
      query: ({ date, listerId, currentWeekOnly } = {}) => ({
        url: `pools/listers/geo`,
        params: { date, listerId, currentWeekOnly }
      }),
      transformResponse: (r: any) => r.data,
      async onCacheEntryAdded(arg, { updateCachedData, cacheEntryRemoved, cacheDataLoaded, getState, dispatch }) {
        const socket = getSocket();

        const listener = ({ payload, type }: SocketMessage) => {
          if (type !== WS_LISTER_LOCATION_UPDATE) return;

          updateCachedData(draft => {
            const existingI = draft.findIndex(e => e.user_id === payload.user_id);
            if (existingI === -1) return;

            draft[existingI].geolocation = payload.geolocation;
          });
        };

        try {
          await cacheDataLoaded;
          socket.emit('subscribe', `lister-geolocation:${arg.listerId}`);
          socket.on('message', listener);
        } catch (error) {}

        await cacheEntryRemoved;
        socket.off('message', listener);
      }
    })
  }),
  overrideExisting: true
});

export const {
  useRequestListVehiclesMutation,
  useGetVehiclesPoolsQuery,
  useCreateVehiclesPoolRowMutation,
  useGetListersQuery,
  useGetSellersQuery,
  useGetRequestsByDayQuery,
  useUpdateRequestPreferableDateMutation,
  useAddRequestToPoolMutation,
  useDeletePoolMutation,
  useSubmitPoolMutation,
  useDuplicatePoolMutation,
  useUpdateVehiclesPoolRowMutation,
  useDeleteDraftVehiclePoolRowsMutation,
  useDeleteVehicleRequestMutation,
  useRemoveVehicleRequestPoolIdMutation,
  useGetListerRequestsByDayQuery,
  useListerMarkAsDoneMutation,
  useGetListerRouteDistancesQuery,
  useUpdateNumberOfVehiclesMutation,
  useOptimizeRoadMutation,
  useGetChatMessagesQuery,
  useSendChatMessageMutation,
  useToggleSchedulePoolMutation,
  useSetListRequestOrderMutation,
  useListerCreatePoolMutation,
  useSaveGeoMutation,
  useGetGeoQuery
} = listVehiclesApi;

export const useFilteredGetVehiclesPoolQuery = (options: { skip?: boolean } = {}) => {
  const filters = useSelector<{ listVehicles: ListVehiclesState }, Record<string, any>>(
    state => state.listVehicles.poolFilters
  );

  const queryResult = useGetVehiclesPoolsQuery({ ...filters }, { ...options });
  return queryResult;
};

export default listVehiclesApi;
